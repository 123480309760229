<template>
  <b-container class="blog mt-0">
    <b-row align-h="between">
      <b-col sm="9" lg="9" md="6" cols="12">
        <h1 class="main-heading">{{ $t("label.blog") }}</h1>
      </b-col>
      <b-col sm="3" lg="3" md="6" cols="12">
        <Button
          class="button button--sm button--outline-primary mx-0 width-100"
          to="/blog"
          :text="$t('button.see_all')"
        ></Button>
      </b-col>
    </b-row>
    <swiper ref="swiperTop" :options="swiperOptionThumbs" class="gallery-top">
      <swiperSlide v-for="slide in blogs" :key="slide.id">
        <lazy-component>
        <NewsComponent :range="65" :news-data="slide" />
        </lazy-component>
      </swiperSlide>
    </swiper>
  </b-container>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";

export default {
  data() {
    return {
      blogs: [],

      swiperOptionThumbs: {
        centeredSlides: false,
        // slidesPerView: "1.5",
        touchRatio: 0.2,
        loop: true,
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          1440: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1280: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          769: {
            slidesPerView: 1
          },
          560: {
            slidesPerView: 1
          }
        },
        slideToClickedSlide: true
      }
    };
  },
  components: {
    NewsComponent: () => import("@/components/NewsComponent")
  },
  created() {
    this.fetchBlogs();
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      swiperTop.controller.control = swiperTop;
    });
  },
  methods: {
    async fetchBlogs() {
      try {
        const page = this.$route.query.page || 1;
        const { data } = await IndexData({ reqName: "news", query: { page } });
        this.blogs = data.news;
      
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style>
.width-100 {
  width: 100% !important;
}
</style>
